import { Injectable, inject } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { FeedbackService } from '@services';
import { BaseEffect } from '@utils/BaseEffect';
import feedbackActions from './feedback.action';
import {
  EMPTY,
  catchError,
  combineLatest,
  exhaustMap,
  of,
  withLatestFrom,
} from 'rxjs';
import { selectOrganization } from '@stores/auth/auth.selector';
import feedbackSelector from './feedback.selector';
import { Pagination, FeedbackPageModel, Organization } from '@models';
import { setErrorToast } from '@stores/shared/shared.actions';
import dayjs from 'dayjs';

@Injectable()
export class FeedbackEffect extends BaseEffect {
  private service = inject(FeedbackService);

  fetchFeedback$ = createEffect(() => {
    return this.action$.pipe(
      ofType(feedbackActions.fetchFeedback),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(feedbackSelector.selectPaginationAndFilter),
        ])
      ),
      exhaustMap(([_, [organization, { filter, pagination }]]) => {
        return this.service
          .get({
            filter: {
              ...this.convertToFilter(
                { ...filter, archive: false },
                pagination
              ),
              include: 'visitor',
            },
            organizationId: organization.id,
          })
          .pipe(
            exhaustMap(data =>
              of(feedbackActions.fetchFeedbackSuccess({ data }))
            ),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Unable to fetch feedback try again.',
                })
              )
            )
          );
      })
    );
  });

  archive$ = createEffect(() => {
    return this.action$.pipe(
      ofType(feedbackActions.archiveFeedback),
      withLatestFrom(this.store.select(selectOrganization)),
      exhaustMap(([{ id }, organization]) =>
        this.service.archive(id, organization.id).pipe(
          exhaustMap(() => {
            this.toastrService.success('Feedback archive successfully');
            return of(feedbackActions.fetchFeedback());
          }),
          catchError(() =>
            of(setErrorToast({ message: 'Internal server error Try Again' }))
          )
        )
      )
    );
  });

  exportFeedback$ = createEffect(() => {
    return this.action$.pipe(
      ofType(feedbackActions.exportToCsv),
      withLatestFrom(
        combineLatest([
          this.store.select(selectOrganization),
          this.store.select(feedbackSelector.selectFilter),
        ])
      ),
      exhaustMap(([_, [organization, filter]]) =>
        this.service
          .exportToCsv({
            filter: {
              ...this.convertToFilter({
                ...filter,
                archive: false,
                organizationId: organization.id,
              }),
            },
          })
          .pipe(
            exhaustMap(response => {
              if (window.confirm('Do you want to save feedbacks?')) {
                this.service.saveFile(
                  response,
                  `feedbacks-${dayjs().format('YYYY-MM-DD hh:mm:ss A')}.csv`
                );
              }
              return EMPTY;
            }),
            catchError(() =>
              of(
                setErrorToast({
                  message: 'Some thing error occurred try again!.',
                })
              )
            )
          )
      )
    );
  });

  private convertToFilter(
    filter: FeedbackPageModel.filter & {
      archive?: boolean;
      organizationId?: Organization['id'];
    },
    pagination?: Pagination
  ) {
    const where: Record<string, unknown> = {};
    if (filter.organizationId) {
      where['organizationId'] = filter.organizationId;
    }

    if (Object.hasOwn(filter, 'archive')) {
      where['archive'] = filter.archive;
    }
    if (filter.dateRange) {
      where['createdDate'] = this.getDateFilter(filter.dateRange);
    }
    const condition: Record<string, unknown> = { where };
    if (filter.order) {
      condition['order'] =
        `${filter.order.field} ${filter.order.direction.toUpperCase()}`;
    } else {
      condition['order'] = `createdDate DESC`;
    }
    if (filter.query) {
      const searchQuery = `.*${filter.query}.*`;
      if (!where['or']) {
        where['or'] = [];
      }
      (where['or'] as Array<any>).push(
        {
          full_name: {
            like: searchQuery,
            options: 'i',
          },
        },
        {
          email_address: {
            like: searchQuery,
            options: 'i',
          },
        },
        {
          mobile_number: {
            like: searchQuery,
            options: 'i',
          },
        }
      );
    }
    if (pagination) {
      condition['page'] = pagination.page;
      condition['limit'] = pagination.offset;
    }
    return condition;
  }
}
