import { Injectable, inject } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { UserService } from '@services/user.service';
import { EMPTY, catchError, exhaustMap, of, withLatestFrom } from 'rxjs';
import { userActions } from './user.actions';
import { setErrorToast } from '@stores/shared/shared.actions';
import { BaseEffect } from '@utils';
import { MatDialogRefStore } from '@services/matDialogRefStore.service';
import { selectUsers } from './user.selector';

@Injectable()
export class UserEffects extends BaseEffect {
  private service = inject(UserService);
  private matDialogRefStore = inject(MatDialogRefStore);
  /**
   * This effect is responsible for loading users.
   * It listens for the 'getUsers' action, then calls the userService to get all users.
   * If the service call is successful, it dispatches the 'getUsersSuccess' action with the users data.
   * If the service call fails, it dispatches the 'setErrorToast' action with an error message.
   */
  loadUsers$ = createEffect(() => {
    return this.action$.pipe(
      // Listen for 'getUsers' action
      ofType(userActions.getUsers),
      withLatestFrom(this.store.select(selectUsers)),
      // Call service with the organizationId and filter from the 'getUsers' action
      exhaustMap(([{ organizationId, filter }, users]) =>
        users.length > 0 && users[0].organizationId === organizationId
          ? EMPTY
          : this.service.getAll(organizationId, filter).pipe(
              // If service call is successful, dispatch 'getUsersSuccess' action with users data
              exhaustMap(({ data }) =>
                of(userActions.getUsersSuccess({ users: data }))
              ),
              // If service call fails, dispatch 'setErrorToast' action with error message
              catchError(() => {
                return of(setErrorToast({ message: 'Error loading users.' }));
              })
            )
      )
    );
  });

  /**
   * This effect is responsible for updating a user.
   * It listens for the updateUser action, then calls the service's updateById method.
   * If the update is successful, it closes the dialog (if a ref is provided), shows a success toast, and dispatches the updateUserSuccess action.
   * If the update fails, it dispatches the handleFailed action.
   */
  update$ = createEffect(() => {
    return this.action$.pipe(
      ofType(userActions.updateUser),
      exhaustMap(({ data, id, organizationId, message, ref }) =>
        this.service.updateById(data, id, organizationId).pipe(
          exhaustMap(user => {
            // If a dialog reference is provided, close the dialog
            ref && this.matDialogRefStore.close(ref);
            // Show a success toast with the provided message, or a default message
            this.toastrService.success(message ?? 'User updated successfully.');
            // Dispatch the updateUserSuccess action with the updated user and the id
            return of(
              userActions.updateUserSuccess({
                data: { changes: user, id },
              })
            );
          }),
          catchError(() => {
            // If the update fails, dispatch the handleFailed action with an error message
            return of(
              userActions.handleFailed({
                error: {
                  message: 'unable to edit  User.',
                },
              })
            );
          })
        )
      )
    );
  });

  /**
   * This effect is responsible for creating a new user.
   * It listens for the 'createUser' action, then calls the service to add the user.
   * If the user is added successfully, it closes the dialog (if any) and dispatches the 'createUserSuccess' action.
   * If an error occurs, it closes the dialog (if any) and dispatches the 'handleFailed' action.
   */
  add$ = createEffect(() => {
    return this.action$.pipe(
      // Listen for the 'createUser' action
      ofType(userActions.createUser),
      exhaustMap(({ user, ref }) => {
        // Call the service to add the user
        return this.service.add(user).pipe(
          exhaustMap(data => {
            // If a dialog reference is provided, close the dialog
            ref && this.matDialogRefStore.close(ref);
            // Show a success message
            this.toastrService.success('User added successfully.');
            // Dispatch the 'createUserSuccess' action
            return of(userActions.createUserSuccess({ user: data }));
          }),
          catchError(e => {
            // If a dialog reference is provided, close the dialog
            // ref && this.matDialogRefStore.close(ref);
            // Dispatch the 'handleFailed' action with the error message

            return of(
              userActions.handleFailed({
                error: {
                  message: e?.error?.message ?? 'Unable to add User',
                },
              })
            );
          })
        );
      })
    );
  });

  delete$ = createEffect(() => {
    return this.action$.pipe(
      ofType(userActions.deleteUser),
      exhaustMap(({ id, organizationId }) =>
        this.service.deleteById(id, organizationId).pipe(
          exhaustMap(() => {
            this.toastrService.success('Users deleted successfully.');
            return of(userActions.deleteUserSuccess({ id }));
          }),
          catchError(() =>
            of(setErrorToast({ message: 'Unable to delete user.' }))
          )
        )
      )
    );
  });
  handleFailed$ = createEffect(() => {
    return this.action$.pipe(
      ofType(userActions.handleFailed),
      exhaustMap(({ error }) => of(setErrorToast(error)))
    );
  });

  setLoading$ = createEffect(() => {
    return this.action$.pipe(
      ofType(userActions.createUser, userActions.updateUser),
      exhaustMap(() => of(userActions.updateLoading({ isLoading: true })))
    );
  });
}
