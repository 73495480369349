import { paginationConfig } from '@constants';
import { Visitor, Pagination, VisitorPageModel } from '@models';

export interface VisitorState {
  data: Visitor[];
  total: number;
  pagination: Pagination;
  filter: VisitorPageModel.filter;
  analytics: Record<
    Visitor['id'],
    { value: VisitorPageModel.Analytics; createdAt: Date }
  >;
}

export const VisitorInitialState: VisitorState = {
  data: [],
  total: 0,
  analytics: {},
  pagination: {
    offset: paginationConfig.defaultSize,
    page: 1,
  },
  filter: {},
};
