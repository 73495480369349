import { createReducer, on } from '@ngrx/store';
import { VisitorInitialState } from './visitor.state';
import VisitorActions from './visitor.action';
import { produce } from 'immer';

export const visitorReducer = createReducer(
  VisitorInitialState,
  on(VisitorActions.updateFilter, function (state, { data: filter }) {
    return { ...state, filter };
  }),
  on(VisitorActions.updatePagination, function (state, { data: pagination }) {
    return { ...state, pagination };
  }),
  on(
    VisitorActions.fetchVisitorSuccess,
    function (state, { data: { count, data } }) {
      return { ...state, data, total: count };
    }
  ),
  on(VisitorActions.updateVisitorSuccess, function (state, { data: visitor }) {
    const filteredData = state.data.map(v => {
      if (v.id !== visitor.id) return v;
      return visitor;
    });
    return { ...state, data: filteredData };
  }),
  on(VisitorActions.fetchAnalyticsSuccess, function (state, { analytic, id }) {
    return produce(state, draft => {
      draft.analytics[id] = {
        value: analytic,
        createdAt: new Date(),
      };
    });
  })
);
